<template>
    <v-card>
        <v-toolbar
            dark
            color="primary"
        >
            <v-toolbar-title>Разметка задания <sub>Режим: {{ modeListLabels[mode] }}</sub></v-toolbar-title>

            <v-btn
                elevation="0"
                color="blue"
                class="ml-4"
                :disabled="!hasChanges"
                @click="save"
            >Сохранить</v-btn>

            <v-spacer></v-spacer>

            <v-btn
                icon
                dark
                @click="closeDialog"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>

        <v-container fluid class="pa-4">
            <v-row>
                <v-col cols="12" class="d-flex flex-column">
                    <span class="mr-2">Инструменты:</span>
                    <tool-bar v-model="activeTool">
                        <v-switch v-model="localPresets.showPoints" label="Отображать углы фигуры" />
                    </tool-bar>
                    <v-divider class="my-2" />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" style="overflow: auto">
                    <svg xmlns="http://www.w3.org/2000/svg"
                        :width="width"
                        :height="height"
                        :viewBox="`0 0 ${width} ${height}`"
                        class="d-block mx-auto"
                        @pointermove="pointerMoveHandler"
                        @pointerup="pointerUpHandler"
                    >
                        <!-- Background nodes -->
                        <g>
                            <circle
                                v-for="node in backgroundNodes"
                                :key="`node_${node.x}_${node.y}`"
                                :cx="getCoordByIndex(node.x)"
                                :cy="getCoordByIndex(node.y)"
                                r="2"
                                :class="{'opacity-0': !checkIsNodeInteractive(node)}"
                            />
                            <!-- Debug info -->
                            <!-- <template v-if="localPresets.axis.length === 2">
                                <text
                                    v-for="node in backgroundNodes"
                                    :key="`text_ node_${node.x}_${node.y}`"
                                    :x="getCoordByIndex(node.x)  + 3"
                                    :y="getCoordByIndex(node.y)"
                                    font-size="13px"
                                >
                                    {{ isPointOnSegment(node, localPresets.axis).toString()[0] }} {{ `${node.x}_${node.y}` }}
                                </text>
                            </template> -->
                        </g>
                        <!-- Background horizontal lines -->
                        <g>
                            <template v-for="y in pointsPerHeight">
                                <line
                                    :key="`h_line_${y - 1}`"
                                    :x1="(pointsOffset / 2) * scale"
                                    :y1="getCoordByIndex(y - 1)"
                                    :x2="(((pointsPerWidth - 1) * (pointsOffset)) + pointsOffset / 2) * scale"
                                    :y2="getCoordByIndex(y - 1)"
                                    stroke="black"
                                    stroke-width="1"
                                    class="opacity-5"
                                />
                            </template>
                        </g>
                        <!-- Background vertical lines -->
                        <g>
                            <template v-for="x in pointsPerWidth">
                                <line
                                    :key="`h_line_${x - 1}`"
                                    :x1="getCoordByIndex(x - 1)"
                                    :y1="(pointsOffset / 2) * scale"
                                    :x2="getCoordByIndex(x - 1)"
                                    :y2="(((pointsPerHeight - 1) * (pointsOffset)) + pointsOffset / 2) * scale"
                                    stroke="black"
                                    stroke-width="1"
                                    class="opacity-5"
                                />
                            </template>
                        </g>
                        <!-- Hint point -->
                        <template v-if="hintPoint && activeTool">
                            <line
                                v-if="('presetAxis' === activeTool && localPresets.axis.length === 1)"
                                :x1="getCoordByIndex(localPresets.axis[0].x)"
                                :y1="getCoordByIndex(localPresets.axis[0].y)"
                                :x2="getCoordByIndex(hintPoint.x)"
                                :y2="getCoordByIndex(hintPoint.y)"
                                :stroke="hintColor"
                                stroke-width="3"
                                stroke-dasharray="4 4"
                            />
                            <line
                                v-if="('presetLine' === activeTool && localPresets.lines.length && localPresets.lines[localPresets.lines.length - 1].length === 1)"
                                :x1="getCoordByIndex(localPresets.lines[localPresets.lines.length - 1][0].x)"
                                :y1="getCoordByIndex(localPresets.lines[localPresets.lines.length - 1][0].y)"
                                :x2="getCoordByIndex(hintPoint.x)"
                                :y2="getCoordByIndex(hintPoint.y)"
                                :stroke="hintColor"
                                stroke-width="3"
                                stroke-dasharray="4 4"
                            />
                            <circle
                                v-if="['presetLine', 'presetAxis'].includes(activeTool)"
                                :cx="getCoordByIndex(hintPoint.x)"
                                :cy="getCoordByIndex(hintPoint.y)"
                                r="5"
                                :fill="hintColor"
                                class="pointer-events-none"
                            />
                            <label-icon
                                v-if="activeTool === 'presetLabel'"
                                :x="getCoordByIndex(hintPoint.x)"
                                :y="getCoordByIndex(hintPoint.y)"
                                :points-offset="pointsOffset"
                                :color="hintColor"
                                class="pointer-events-none opacity-5"
                            />
                        </template>
                        <!-- Preset axis -->
                        <g>
                            <line
                                v-if="localPresets.axis.length === 2"
                                :x1="getCoordByIndex(localPresets.axis[0].x)"
                                :y1="getCoordByIndex(localPresets.axis[0].y)"
                                :x2="localPresets.axis[1] ? getCoordByIndex(localPresets.axis[1].x) : null"
                                :y2="localPresets.axis[1] ? getCoordByIndex(localPresets.axis[1].y) : null"
                                stroke-width="2"
                                stroke="green"
                                class="c-pointer"
                                :class="{'pointer-events-none': activeTool !== 'generalEraser'}"
                                @click.stop="removePresetAxis"
                            />
                        </g>
                        <!-- Perpendicular lines -->
                        <g v-if="mirroredNodes.length && localPresets.showPoints">
                            <line
                                v-for="(node, index) in linesNodes"
                                :key="`pl_${index}`"
                                :x1="getCoordByIndex(node.x)"
                                :y1="getCoordByIndex(node.y)"
                                :x2="getCoordByIndex(mirroredNodes[index].x)"
                                :y2="getCoordByIndex(mirroredNodes[index].y)"
                                stroke="green"
                                stroke-width="2"
                                stroke-dasharray="4 4"
                                class="pointer-events-none opacity-5"
                            />
                        </g>
                        <!-- Mirrored Nodes -->
                        <g v-if="localPresets.showPoints">
                            <circle
                                v-for="(node, index) in mirroredNodes"
                                :key="`mirrored_node_${index}`"
                                :cx="getCoordByIndex(node.x)"
                                :cy="getCoordByIndex(node.y)"
                                r="4"
                                fill="green"
                                class="pointer-events-none opacity-5"
                            />
                        </g>
                        <!-- Answer lines -->
                        <g v-if="localPresets.showPoints">
                            
                            <template v-for="(line, index) in answerLines">
                                <line
                                    v-if="line.length === 2"
                                    :key="`answer_line_${index}`"
                                    :x1="getCoordByIndex(line[0].x)"
                                    :y1="getCoordByIndex(line[0].y)"
                                    :x2="getCoordByIndex(line[1].x)"
                                    :y2="getCoordByIndex(line[1].y)"
                                    stroke-width="3"
                                    stroke="orange"
                                    class="pointer-events-none opacity-5"
                                />
                            </template>
                        </g>
                        <!-- Preset lines -->
                        <g>
                            <template v-for="(line, index) in localPresets.lines">
                                <line
                                    v-if="line.length === 2"
                                    :key="`preset_line_${index}`"
                                    :x1="getCoordByIndex(line[0].x)"
                                    :y1="getCoordByIndex(line[0].y)"
                                    :x2="line[1] ? getCoordByIndex(line[1].x) : null"
                                    :y2="line[1] ? getCoordByIndex(line[1].y) : null"
                                    class="presetLine c-pointer"
                                    :class="{
                                        'pointer-events-none': activeTool !== 'generalEraser',
                                        'eraseble': activeTool === 'generalEraser' && erasableLines.includes(index)
                                    }"
                                    @click.stop="removePresetLine(index)"
                                    @mouseenter="calcErasables(index)"
                                    @mouseleave="erasableLines = []"
                                />
                            </template>
                            <g v-if="localPresets.showPoints">
                                <circle
                                    v-for="(node, nIndex) in linesNodes"
                                    :key="`nodes_${nIndex}`"
                                    :cx="getCoordByIndex(node.x)"
                                    :cy="getCoordByIndex(node.y)"
                                    r="5"
                                    fill="orange"
                                    class="pointer-events-none"
                                />
                            </g>
                        </g>
                        <!-- Preset labels -->
                        <g>
                            <label-icon
                                v-for="(label, index) in localPresets.labels"
                                :key="`preset_lable_${index}`"
                                :x="getCoordByIndex(label.position.x)"
                                :y="getCoordByIndex(label.position.y)"
                                :text="label.text"
                                :points-offset="pointsOffset"
                                :background="label.showBackground ? 'white' : 'transparent'"
                                color="blue"
                                :class="{'pointer-events-none': !['generalEraser', null].includes(activeTool)}"
                                @click.native.stop="onPresetLabelClick(index)"
                            />
                        </g>
                    </svg>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog
            :value="labelSettings"
            max-width="500"
            @click:outside="labelSettings = null"
        >
            <template v-if="labelSettings">
                <v-card class="d-flex flex-column pa-5">
                    <v-text-field
                        v-model="labelSettings.text"
                        placeholder="Текст лейбла"
                    />
                    <v-checkbox v-model="labelSettings.showBackground" label="Отображать подложку" />
                    <div>
                        <v-btn outlined @click="labelSettings = null">Закрыть</v-btn>
                    </div>
                </v-card>
            </template>
        </v-dialog>

        <div class="text--secondary mx-auto" style="width: fit-content">
            <p class="ma-0">Подсказка:</p>
            <p class="ma-0">Клик по установленному лейблу без активного инструмента отображает окно настроек лейбла.</p>
            <p class="ma-0">Ось может быть установлена только одна.</p>
            <p class="ma-0">Удаление оси удаляет остальные предустановленные линии тоже.</p>
            <p class="ma-0">После установки оси блокируются точки на поле, которые не могут быть использованы в соответствии с получившемся углом симметрии.</p>
        </div>
    </v-card>
</template>

<script>
import ToolBar from './ToolBar.vue'
import LabelIcon from './LabelIcon.vue'
import {
    getCrossCoord, rotatePoint, getLineLengthByCoords,
    getCoordsForLineWithLength, isPointOnSegment
} from '@/helpers/Сoordinates.js'
import { modeList, modeListLabels } from './dictionaries'

const getDefaultPresets = () => ({
                lines: [],
                labels: [],
                axis: [],
                showPoints: true
            })


export default {
    components: { ToolBar, LabelIcon },
    props: {
        value: { type: Boolean, default: false },
        lines: { type: Array, default: () => ([]) },
        presets: { type: Object, required: true },
        pointsOffset: { type: Number, default: 20 },
        pointsPerWidth: { type: Number, default: 10 },
        pointsPerHeight: { type: Number, default: 10 },
        mode: { type: String, default: null } // null | 'independentSections'
    },
    data () {
        return {
            localPresets: null,
            hasChanges: false,
            scale: 2,
            activeTool: null,
            hintPoint: null,
            labelSettings: null,
            answerLines: [],
            erasableLines: []
        }
    },
    watch: {
        localPresets: {
            handler () { this.hasChanges = true },
            deep: true
        },
        mirroredNodes: {
            handler () {
                if (this.mode === modeList.independentSections) {

                    this.answerLines = this.mirroredSections
                    return
                }

                const lines = []
                for (let index = 1; index < this.mirroredNodes.length; index++) {
                    const startPoint = this.mirroredNodes[index-1]
                    const endPoint = this.mirroredNodes[index]
                    lines.push([
                        { x: Math.round(startPoint.x), y: Math.round(startPoint.y) },
                        { x: Math.round(endPoint.x), y: Math.round(endPoint.y) }
                    ])
                }
                // Add close line
                if (
                    this.localPresets.lines.length > 2 &&
                    this.localPresets.lines.length === this.mirroredNodes.length &&
                    this.localPresets.lines[this.localPresets.lines.length - 1].length === 2 // last line has two points
                ) {
                    const startPoint = this.mirroredNodes[this.mirroredNodes.length - 1]
                    const endPoint = this.mirroredNodes[0]
                    lines.push([
                        { x: Math.round(startPoint.x), y: Math.round(startPoint.y) },
                        { x: Math.round(endPoint.x), y: Math.round(endPoint.y) }
                    ])
                }
                this.answerLines = lines
            },
            deep: true
        }
    },
    computed: {
        modeListLabels () { return modeListLabels },
        isPointOnSegment () { return isPointOnSegment },
        width () {
            return this.pointsOffset * this.pointsPerWidth * this.scale
        },
        height () {
            return this.pointsOffset * this.pointsPerHeight * this.scale
        },
        backgroundNodes () {
            const nodes = []
            for (let y = 0; y < this.pointsPerHeight; y++) {
                for (let x = 0; x < this.pointsPerWidth; x++) {
                    nodes.push({ x, y })
                }
            }
            return nodes
        },
        hintColor () {
            const tool2color = {
                presetAxis: 'rgba(0, 255, 0, .5)', // green
                presetLine: 'rgba(255, 165, 0, .5)', // orange
                presetLabel: 'rgba(0, 0, 255, .5)' // blue
            }
            return this.activeTool ? tool2color[this.activeTool] : 'black'
        },
        /**
         * Массив точек не объединенных в линии, собираются из localPresets.lines
         */
        linesNodes () {
            const nodes = []
            const nodesHasPoint = (point) => nodes.findIndex(node => node.x === point.x && node.y === point.y) > -1

            this.localPresets.lines.forEach((line) => {
                line.forEach((point) => {
                    if (!nodesHasPoint(point))
                        nodes.push(point)
                })
            })
            return nodes
        },
        /**
         * @returns {Number} угол в радианах
         */
        axisAngle () {
            if (this.localPresets?.axis?.length !== 2) { return 0 }

            const aPoint = this.localPresets.axis[0]
            const bPoint = this.localPresets.axis[1]
            const width = Math.abs(aPoint.x - bPoint.x)
            const height = Math.abs(aPoint.y - bPoint.y)
            if (width === 0) return Math.PI / 2 // vertical line
            if (height === 0) return 0 // horizontal line

            const alpha = Math.atan2(height, width)
            return alpha
        },
        /**
         * Точки отраженные от linesNodes в соответсвии с осью симметрии
         */
        mirroredNodes () {
            if (this.localPresets?.axis?.length !== 2) { return [] }
            return this.linesNodes.map((node) => this.getMirroredNode(node))
        },
        /**
         * Отрезки отраженные от localPresets.lines в соответсвии с осью симметрии
         */
        mirroredSections () {
            return this.localPresets.lines.filter(line => line.length === 2).map(line => {
                return [
                    this.getMirroredNode(line[0]),
                    this.getMirroredNode(line[1])
                ]
            })
        }
    },
    created () {
        this.localPresets = Object.keys(this.presets).length ? _.cloneDeep(this.presets) : getDefaultPresets()
        this.answerLines = this.lines.length ? _.cloneDeep(this.lines) : []
    },
    methods: {
        /**
         * Хендлер перемещения курсора
         * @param {PointerEvent} event Событие курсора
         */
        pointerMoveHandler (event) {
            this.hintPointMove(event)
        },
        /**
         * Хендлер "отжатия" кнопки курсора
         * @param {Any} payload Любой набор данных
        */
        pointerUpHandler (payload) {
            if (!this.activeTool) { return false }
            const funcPartName = this.activeTool.charAt(0).toUpperCase() + this.activeTool.slice(1)
            this[`add${funcPartName}`]?.({...this.hintPoint}, payload)
        },
        /**
         * Метод добавляет ещё один элемент (линию) в массив localPresets.axis
         * @param {Object {x: Number, y: Number}} Координата точки. Либо первой в линии, либо завершающей
         */
        addPresetAxis ({x, y}) {
            if (this.localPresets.axis.length >= 2) { return }
            this.localPresets.axis.push({x, y})
            
            // On line finished
            if (this.localPresets.axis.length === 2) {
                this.localPresets.axis = this.removeZeroLines([this.localPresets.axis])?.[0] || []
                this.activeTool = null
                this.localPresets.lines = [] // Обнуляем линии, потому как возможно с новой осью предыдущая линия будет недопустима
            }
        },
        /**
         * Метод удаляет элемент (линию) из пресета axis
         */
        removePresetAxis () {
            this.localPresets.axis = []
            this.localPresets.lines = [] // Обнуляем линии, потому как возможно с новой осью предыдущая линия будет недопустима
        },
        /**
         * Метод добавляет ещё один элемент (лейбл) в массив localPresets.labels
         * @param {Object {x: Number, y: Number}} Координата точки. Либо первой в линии, либо завершающей
         */
        addPresetLabel ({x, y}) {
            this.localPresets.labels.push({ text: 'Лейбл', position: {x, y}, showBackground: false })
            this.activeTool = null
        },
        /**
         * Метод удаляет элемент (лейбл) из массива localPresets.labels по индексу
         * @param {Number} labelIndex Индекс лейбла
         */
        removePresetLabel (labelIndex) {
            this.localPresets.labels.splice(labelIndex, 1)
        },
        onPresetLabelClick (labelIndex) {
            if (this.activeTool === 'generalEraser') { this.removePresetLabel(labelIndex) }
            if (this.activeTool === null) { this.labelSettings = this.localPresets.labels[labelIndex] }
        },
        /**
         * Метод добавляет ещё один элемент (линию) в массив localPresets.lines
         * @param {Object {x: Number, y: Number}} Координата точки. Либо первой в линии, либо завершающей
         */
        addPresetLine ({x, y}) {
            if (!this.checkIsNodeInteractive({x, y})) { return false }
            const newLine = {x, y}
            const unfinishedLine = this.localPresets.lines.find((l) => l.length === 1)
            // Finish line
            if (unfinishedLine) {
                unfinishedLine.push(newLine)
                this.localPresets.lines = this.removeSameLines(this.localPresets.lines)
                this.localPresets.lines = this.removeZeroLines(this.localPresets.lines)
            } else {
            // Start new line
                this.localPresets.lines.push([newLine])
            }
        },
        /**
         * Метод удаляет все линии из массива localPresets.lines
         */
        removePresetLine (lineIndex) {
            this.localPresets.lines = this.localPresets.lines.splice(0, lineIndex);
        },
        /**
         * Метод удаляющий из переданного массива линии, в которых точка начала равна точке конца
         * @param {Array} arr Массив с линиями
         */
        removeZeroLines (arr) {
            return arr.filter((el) => !(el[0].x === el[1].x && el[0].y === el[1].y))
        },
        /**
         * Метод очищает переданный массив от дубликатов линиий, оставляя только уникальные
         * @param {Array} arr Массив с линиями
         */
        removeSameLines (arr) {
            const lines = []
            arr.forEach((line) => {
                const lineIndex = lines.findIndex((l) => (l[0]?.x === line[0]?.x &&
                                                        l[0]?.y === line[0]?.y &&
                                                        l[1]?.x === line[1]?.x &&
                                                        l[1]?.y === line[1]?.y) ||
                                                        (l[0]?.x === line[1]?.x &&
                                                        l[0]?.y === line[1]?.y &&
                                                        l[1]?.x === line[0]?.x &&
                                                        l[1]?.y === line[0]?.y)
                                )
                if (lineIndex < 0)
                    lines.push(line)
            })
            return lines
        },
        /**
         * Метод обработки перемещения элемента подсказки
         * @param {PointerEvent} event Событие курсора
         */
        hintPointMove (event) {
            if (!this.hintPoint) { this.hintPoint = { x: 0, y: 0 } }
            this.hintPoint = this.activeTool === 'presetLabel' ?
                                this.getTopLeftNeighborNodeCoords(event.offsetX, event.offsetY) :
                                this.getNeighborNodeCoords(event.offsetX, event.offsetY)
        },
        /**
         * Метод возвращает индекс ближайшего узла по переданным координатам
         * @param {Number} x X координата в пикселях
         * @param {Number} y Y координата в пикселях
         * @returns {Object {x: Number, y: Number}} 
         */
        getNeighborNodeCoords (x, y) {
            let xIndex = _.round(  _.floor( ((x - this.pointsOffset) / (this.width - this.pointsOffset)) * this.pointsPerWidth ), 1)
            if (xIndex < 0) { xIndex = 0 }
            if (xIndex >= this.pointsPerWidth - 1) { xIndex = this.pointsPerWidth - 1 }

            let yIndex = _.round(  _.floor( ((y - this.pointsOffset) / (this.height - this.pointsOffset)) * this.pointsPerHeight ), 1)
            if (yIndex < 0) { yIndex = 0 }
            if (yIndex >= this.pointsPerHeight - 1) { yIndex = this.pointsPerHeight - 1 }

            return { x: xIndex, y: yIndex }
        },
        /**
         * Метод возвращает индекс ближайшего верхнего левого узла по переданным координатам
         * @param {Number} x X координата в пикселях
         * @param {Number} y Y координата в пикселях
         * @returns {Object {x: Number, y: Number}}
         */
        getTopLeftNeighborNodeCoords (x, y) {
            let xIndex = _.floor(  _.floor( ((x - this.pointsOffset) / this.width) * this.pointsPerWidth ), 1)
            if (xIndex < 0) { xIndex = 0 }
            if (xIndex >= this.pointsPerWidth - 1) { xIndex = this.pointsPerWidth - 2 }

            let yIndex = _.floor(  _.floor( ((y - this.pointsOffset) / this.height) * this.pointsPerHeight ), 1)
            if (yIndex < 0) { yIndex = 0 }
            if (yIndex >= this.pointsPerHeight - 1) { yIndex = this.pointsPerHeight - 2 }

            return { x: xIndex, y: yIndex }
        },
        /**
         * Метод высчитывает координаты одного из значений точки по её индексу
         * @param {Number} value Индекс
         * @returns {Number}
         */
        getCoordByIndex (value) {
            return ((value * this.pointsOffset) + this.pointsOffset / 2) * this.scale
        },
        getMirroredNode (node) {
            if (isPointOnSegment(node, this.localPresets.axis)) { return node }

            const leftAxisPoint = this.localPresets.axis[0].x < this.localPresets.axis[1].x ? this.localPresets.axis[0] : this.localPresets.axis[1]
            const rightAxisPoint = this.localPresets.axis[0].x > this.localPresets.axis[1].x ? this.localPresets.axis[0] : this.localPresets.axis[1]
            const leftSideHigher = leftAxisPoint.y < rightAxisPoint.y // check is left point of axis higher then right point

            const angle = this.axisAngle + Math.PI
            const reflectDirection = leftSideHigher ? 1 : -1

            const virtualPoint = rotatePoint({ x: node.x, y: 1000 * -1}, node, angle * reflectDirection)
            const crossPoint = getCrossCoord(virtualPoint, node, this.localPresets.axis[0], this.localPresets.axis[1])
            const lineLength = getLineLengthByCoords(node, crossPoint)
            return getCoordsForLineWithLength(node, crossPoint, lineLength * 2)
        },
        checkIsNodeInteractive (node) {
            if (this.localPresets.axis.length !== 2) { return true }

            if (isPointOnSegment(node, this.localPresets.axis)) { return true }

            const inaccuracy = 0.01 // Допустимая погрешность в долях шага
            const mirroredNode = this.getMirroredNode(node)

            if (mirroredNode.x < 0 || mirroredNode.y < 0) { return false }
            if (mirroredNode.x >= this.pointsPerWidth || mirroredNode.y >= this.pointsPerHeight) { return false }
            
            const biggerX = Math.floor(mirroredNode.x)
            const biggerY = Math.floor(mirroredNode.y)
            const smallerX = Math.ceil(mirroredNode.x)
            const smallerY = Math.ceil(mirroredNode.y)

            const nearestPoints = [{x: biggerX, y: biggerY}, {x: smallerX, y: smallerY}, {x: biggerX, y: smallerY}, {x: smallerX, y: biggerY}]

            for (const point of nearestPoints) {
                const xDiff = Math.abs(point.x - mirroredNode.x)
                const yDiff = Math.abs(point.y - mirroredNode.y)
                if (xDiff <= inaccuracy && yDiff <= inaccuracy ) { return true }
            }
            return false
        },
        checkIsPointsEqual (point1, point2) {
            return point1.x === point2.x && point1.y === point2.y
        },
        /**
         * Метод закрывает диалоговое окно
         */
        closeDialog () {
            this.$emit('input', false)
        },
        /**
         * Метод сохранения. Обновляем данные пропсов.
         */
        save () {
            this.$emit('update:presets', _.cloneDeep(this.localPresets))
            this.$emit('update:lines', _.cloneDeep(this.answerLines))
            this.hasChanges = false
        },
        calcErasables (lineIndex) {
            this.erasableLines = this.localPresets.lines.map((line, index) => lineIndex > index ? null : index).filter(item => item !== null);
        }
    }
}
</script>

<style lang="scss" scoped>
    .presetLine {
        stroke-width: 3;
        stroke: orange;

        &.eraseble {
            stroke: red;
        }
    }
</style>